
//--->Book Now Section
.book-now-section{
    background: url("../images/book-now-bg.png") no-repeat scroll center center / cover;
    padding: 65px 0;
}
//--->Book Now Section

//--->Our-Work Section
.our-work-2 {

    .work-details-inner{
        top: 43%;
        left: 46%;
        a{
            font-size: 40px;
            color: var(--#{$prefix}white);
        }
    }
    .work-img::after {
        content: "";
        height: 100%;        
        top: 0;    
        left: 0;    
        width: 100%;
      }
      .our-work{
        position: absolute;
        top: calc(50% - 90px);
        left: calc(50% - 90px);
        background-color: var(--#{$prefix}primary);
        height: 180px;
        width: 180px;
        transform: rotate(45deg);
        display: flex;
        z-index: 9;
        align-items: center;
        justify-content: center;
        h4{
            transform: rotate(-45deg);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
        }
      }

    @media screen and (max-width: 390px) {
        .work-details-inner{
            top: 30%;
            left: 37%;
        }
        
    }
    @media screen and (min-width: 391px) and (max-width: 639px){
        .work-details-inner{
            top: 36%;
            left: 43%;
        }
        
    }
}
//--->Our-Work Section

//--->Trendy-Plans Section
.trendy-plans-section{

    .tab-content-outer{
        padding-top: 97px;
    }
    .tab-content-inner{
        background: rgba(0, 0, 0, 0.7);
        margin: 14px 95px 48px auto;
        max-width: 220px;
        padding: 50px 12px;
        text-align: center;
        position: relative;
        .saparator2{
            background-position: center;
            margin-bottom: 1rem;
        }
    }
    .tab-content-inner::after {
        border: 3px solid hsl(0, 0%, 100%);
        content: "";
        height: calc(100% + 20px);
        left: -10px;
        position: absolute;
        top: -10px;
        width: calc(100% + 20px);
      }

    .plans-item{
        .nav{
            display: block;
        }
        p{
            color: var(--#{$prefix}body-color);
            margin-bottom: 4px;
        }
        a{
            padding-bottom: 35px;
            padding-left: 46px;
            padding-top: 30px;
            border-left: 4px solid transparent;
            transition: $transition-base-lg;
            &:hover{
                border-left: 4px solid var(--#{$prefix}primary);
            }
        }
       
    }
    .tab-single-content-1{
        background: url("../images/plan/maincure.jpg") no-repeat scroll center center / cover;
        
    }
    .tab-single-content-2{
        background: url("../images/plan/plans.png") no-repeat scroll center center / cover;
        
    }
    .tab-single-content-3{
        background: url("../images/plan/nail-art.jpg") no-repeat scroll center center / cover;
        
    }
    .tab-single-content-4{
        background: url("../images/plan/pedicure.jpg") no-repeat scroll center center / cover;
        
    }
    
}
//--->Trendy-Plans Section

//---> Client-Review Section
.happy-clients{
    background: url("../images/client/client-say-style2-bg.png") no-repeat fixed center center / cover;
}
.swiper-btn {

    .btn-style-2{
        --swiper-theme-color: transparent;
        --border-color: rgba(var(--#{$prefix}white-rgb), .3);
        height: 38px;
        width: 38px;
        transition: $transition-base-lg; 
        color: var(--border-color);  
        &:hover{
            --border-color: rgba(var(--#{$prefix}white-rgb), 1);                          
        }    
        &.swiper-button-next{
            border-left: 1px solid var(--border-color); 
        }
        &.swiper-button-prev{
            border-right: 1px solid var(--border-color); 
        }
        
       
    }
}
    
.review{
    h5{
        color: var(--#{$prefix}white);
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: .5rem;
        &::before{
            content: "“";
            font-family: "Droid Serif",serif;
            font-size: 50px;
            left: 0;
            position: absolute;
            z-index: 9;
            top: -30px;
            transform: rotate(180deg);
            width: calc(100% + 190px);
        }
        &::after{
            content: "“";
            font-family: "Droid Serif",serif;
            font-size: 50px;
            left: 0;
            position: absolute;
            z-index: 9;
            top: -12px;
            width: calc(100% + -190px);
        }
    }
    
}
