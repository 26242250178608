.btn{
    text-transform:uppercase;
    --#{$prefix}btn-border-radius: 0;
}
.btn-primary{    
    --#{$prefix}btn-color: var(--#{$prefix}white);
    --#{$prefix}btn-hover-color: var(--#{$prefix}white);
    --#{$prefix}btn-hover-bg: var(--#{$prefix}black);          
}
.btn-outline-primary{
    --#{$prefix}btn-color: var(--#{$prefix}dark);
    --#{$prefix}btn-hover-color: var(--#{$prefix}white);
    --#{$prefix}btn-border-width : 2px ;
    --#{$prefix}btn-border-color: var(--#{$prefix}primary);
    --#{$prefix}btn-hover-bg: var(--#{$prefix}primary);  
    --#{$prefix}btn-padding-y :1rem;
          
}
.btn-secondary{
    --#{$prefix}btn-color: var(--#{$prefix}white);
    --#{$prefix}btn-bg: var(--#{$prefix}primary);
    --#{$prefix}btn-hover-color: var(--#{$prefix}white);
    --#{$prefix}btn-hover-bg: var(--#{$prefix}black);    
}
 
    
  
