.carousel-caption {
    top: 10%;
    padding-top: 6rem;
}
.carousel-item{
    background: black;
    .slider-img{
        opacity: .4;
    }
}
.hero-2{
    .carousel-caption {
        top: 25%;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1400px){
    .hero-2{
        #carouselHero{
            height: 72vh;
            .carousel-caption {
                padding-top: 12rem;
            }
        }

    }
}

@media screen and (max-width: 1400px) {
    #carouselHero{
        height: 67vh;
        .carousel-inner,.carousel-item,.slider-img{
            height: 100%;
            object-fit: cover;
        }
        .carousel-caption {
            top: 4%;
        }
    }   
}

.banner-section{
    background: url(../images/about-header-bg.png) no-repeat scroll center center / cover;
    position: relative;
    padding: 120px 0;
}