.card-post , .card-blog-post{
    background-color: var(--#{$prefix}purple);
    .graph{
        background-color: var(--#{$prefix}primary);
        position:absolute;
        top:3%;
        right:5%;
        a{
            width:43px;
            height:38px;
        }
        i{
            color:var(--#{$prefix}white);
            top:0;
            font-size: 24px;
        }
    }
    //-->graph
    i{
        position:relative;
        top:2px;
        color:var(--#{$prefix}primary);
        font-weight: bold;
    }
    .bottom-border{
        border-style: none none dashed;
        border-width: 1px;      
    }
    .top-border{
        border-style: dashed none none none;
        border-width: 1px;      
    }
    span{
        color:var(--#{$prefix}primary);
    }
    .link-hover:hover{
        color:var(--#{$prefix}primary);
    }

    .right-border{
        position: absolute; 
        top:0;
        right:0;  
        color:var(--#{$prefix}dark);   

    }
    .social-reviews{
        font-size: 15px;
    }
   
}
.card-blog-post{
    .graph{
        top:4%;
        right:3%;
    }    
    .card-text-middle{
        background-color: white;
        padding: 24px 55px;
    }
    @media screen and (max-width: 640px){
        .card-text-middle{
            padding: 4px 20px;
        }
    }
}
.link-hover{
    transition: $transition-base-lg;
    font-size: 15px;
    font-weight: bold;
    .fa-solid{
        top:0;
        color:var(--#{$prefix}dark); 
        transition: $transition-base-lg;           
    }
    &:hover{
        .fa-solid{
            color:var(--#{$prefix}primary);
        }            
    }
}