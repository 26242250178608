.card-experience{
    --bs-card-spacer-y:2rem;
    --bs-card-spacer-x:2rem;
    transition: $transition-base-lg;
    &:hover {
        .icon-circle-wrap i {
            background-color: var(--#{$prefix}primary);
            color: var(--#{$prefix}white, #fff);
            transition: $transition-base-lg;
        }
        filter: drop-shadow(0px 40px 70px rgba(75, 3, 116, 0.15));
    }
    .saparator2{
        background-position: center center;
    }
}
.icon-circle-wrap {
    width: 78px;
    height: 77px;
    text-align: center;
    border: 2px solid var(--#{$prefix}primary);
}
.icon-circle-wrap i {
    display:inline-block;
    line-height: 72px;
    width: 72px;
    height: 73px;
    border: 2px solid #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    color: var(--#{$prefix}primary);
}    
.card-experience-icons {
    font-size: 1.375rem;
    color: var(--#{$prefix}primary);
}