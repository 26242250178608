.sticky{
    position: fixed;
    z-index: 9999;
    width: 100%;
    top: 0;
    left: 0;
}

.header-section{    
    &.sticky-navbar{
        &.sticky{
            background-color: var(--#{$prefix}black);
            .top-bar{
                display: none;
            }
            .navbar-2{                
                margin-top: 0;
                --#{$prefix}navbar-padding-x: 0;
            }
        }
    }
    .nav-link{
        position: relative;
        &.active:before{
            content: "";
            width: 100%;
            height: 4px;
            bottom: 0;
            left: 0;
            position: absolute;
            background-color: var(--#{$prefix}primary);
        }
    }
}
.offcanvas-header-style-2{
    padding: 29px 32px 0 0;
}

.header-style-2{    
    .navbar-2{
        --#{$prefix}navbar-padding-x: 3.125rem;
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: 30px;
    }    
    .nav-link{
        --#{$prefix}nav-link-padding-y: 35px;
    }
    .offcanvas{
        background-color: var(--#{$prefix}dark);
    }
}

@media screen and (max-width:1200px){
    .header-section{      
        .top-bar{
            display: none;
        }
        .navbar{            
            --#{$prefix}navbar-padding-x: 1rem;   
                  
        } 
        .nav-link{
            --#{$prefix}nav-link-padding-y:.6rem;
            // --#{$prefix}nav-link-padding-x:.6rem;
            &.active::before{
                background-color: transparent;
            }
        }          
    }
    .header-style-1,
    .header-style-2 {
        .top-bar{
            display: block !important;
        }
    }

    .header-style-2{    
        .navbar-2{           
            margin-top: 0;
        } 
    }
}

// ----> Overlay
.navbar{
    
    @media screen and (min-width:1200px){
        .dropdown-toggle::after{
            display: none;
        }
        .nav-item:hover{
            .dropdown-menu{
                display: block;
            }
        }
    }
    .dropdown-menu{
        --#{$prefix}dropdown-link-hover-bg:none;
        --#{$prefix}dropdown-link-hover-color:var(--#{$prefix}primary);
        width: fit-content;
    }
    a.navbar-toggler{
        padding: 8px 4px;
    }
}


@media screen and (max-width:992px){
    .topbar1.sticky{
        display: block;
    }    
    .navbar .navbar-nav > li > a.active::after{
        content: none;
    }
}

.offcanvas-header-style-2{
    background-color:rgb(33, 37, 41);
}
.fa-circle-info{
	font-size: 24px;
	color: var(--#{$prefix}primary);
}
a.navbar-toggler{
    border: none;
}

// ----> HOME-1
.topbar1-contact p {
    color: var(--#{$prefix}black);;
}
.topbar1-contact:hover{
    .topbar1-icons{
        color: var(--#{$prefix}white);
        background-color: var(--#{$prefix}primary);
    }
}
.topbar1-icons{
    border: 1px solid var(--#{$prefix}gray-700);
    border-radius: 50%;
    font-size: 23px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center; 
    transition: $transition-base-lg;
}

.social-icons{
    a{
        color: var(--#{$prefix}dark);
        border: 1px solid var(--#{$prefix}dark);
        font-size: 13px;
        &:hover{
            color: var(--#{$prefix}white);
            background-color: var(--#{$prefix}primary);
            border: 1px solid var(--#{$prefix}primary);
        }        
    }
}

.language-dropdown{
    .dropdown-menu{
        --bs-dropdown-min-width: 0;
    }
    .dropdown-menu.show{
        left: 16px;
    }
}
.add-to-cart-top i{
    font-size: 30px;
    color: var(--#{$prefix}primary);
}
.topbar-separator-1{
    border-right: 1px solid var(--#{$prefix}gray-400);
}
.topbar-separator-2{
    border-left: 1px solid var(--#{$prefix}gray-400);
}
@media screen and (max-width: 1200px) {
    .topbar-separator-1,.topbar-separator-2{
        border: none;
    }
}


.navbar-nav{
    --#{$prefix}nav-link-color: var(--#{$prefix}white);
    --#{$prefix}nav-link-hover-color: var(--#{$prefix}primary);
}

 .modal-content{
    position: absolute;    
    top: 196px;   
    right: 94px;
}

  @media screen and (max-width: 640px) {
    .modal-content{
        position: absolute;    
        top: 75px; 
        right: 0;  
        
        }
    .btn-header{
        padding: 15px;
        border-radius: 5px;
        width: fit-content;
    }
}
.btn-header{
    --#{$prefix}navbar-nav-link-padding-x: 30px;
    color: var(--#{$prefix}primary);
    background-color: var(--#{$prefix}black);
    text-transform: uppercase;
    transition: $transition-base-lg;
    &:hover{        
        color: var(--#{$prefix}white);
        background-color: var(--#{$prefix}primary);
    }
}
.custom-nav-item{
    background-color: var(--#{$prefix}black);
    &:hover{
        .nav-link{
            color: var(--#{$prefix}white);
        }       
    }
    .search-button{
        background: transparent;
        border: none;
        position: absolute;
        top: 30%;
        right: 2%;
        i{
            color: white;
        }
    }
}

// ----> HOME-1

// ----> HOME-2
.topbar2{
    .follow-icons{
        i{
            color: var(--#{$prefix}primary);
        }
    }
    ul{
        margin-bottom: 0;
    }
}
.topbar2-contact{
    i{
        color: var(--#{$prefix}primary);
        padding-right: .8rem;
        font-size: 13px;
    }
    small{
        color: var(--#{$prefix}white);
    }
}

@media screen and (max-width: 992px) {
    .header-style-2{
        .nav-link{
            padding: 13px 0;
        }
    }    
}