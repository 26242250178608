
.side-border{
    border-right: 1px solid var(--#{$prefix}body-color);
}

@media screen and (max-width: 1200px){
    .side-border{
        border-right: none;
    }
}

.follow-icons {
    a{        
        --#{$prefix}link-hover-color : var(--#{$prefix}primary);
        padding: 5px;        
    }
}
.footer-nav{

    background-color: var(--#{$prefix}black);
    color:var(--#{$prefix}white);
    ul{
        row-gap: 1rem;
        column-gap: 2rem;
    }
    li>a{
        color:var(--#{$prefix}white);
        font-size: 14px;
        &:hover{
            color:var(--#{$prefix}primary);
        }
    }
}
footer{
    .cart-btn{
        input{
            padding:20.3px;
        }
        .fa-paper-plane{
            font-size: 22px;
        } 
    }
    .footer-title{
        margin-bottom: 30px;
    }
       
    .topbar1-icons{
        color:var(--#{$prefix}black-200 ,$black-200);
        border-color: var(--#{$prefix}black-200 ,$black-200);
    }
    .flickr-feed img{
        margin-bottom: 10px;
        margin-right: 5px;
        cursor: crosshair;
    }
    .form-control{
        border: none;
        background: hsla(0, 0%, 0%, 0.5) none repeat scroll 0 0;
        transition: $transition-base-lg;
        &:focus{
            background: hsla(0, 0%, 0%, 0.5) none repeat scroll 0 0;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
        }
    }
    .copyright-bar{
        .border-top{
            color: hsla(0, 0%, 100%, 0.1);
        }
    }   
}
.footer-style-3{
    max-width: 800px;
    width: 100%;
    .footer-menu{
        li{
            padding : .5rem 0;
        }
    }
    span{       
        border-right: 1px solid var(--#{$prefix}gray-400);
        margin-right: 22px;        
    }
    .topbar1-icons{
        color:var(--#{$prefix}primary);
    }
}
@media screen and (max-width: 991px){
    .footer-style-3{
        span{
            display: none;
        }
    }
}