 
.customise-form{
    padding: 8px;
    border: 1px solid var(--#{$prefix}gray-400); 

    &:focus{
        border: 1px solid var(--#{$prefix}gray-400); 
        outline: 1px solid var(--#{$prefix}gray-400); 
    }
}
.input-group-text{
    border: 1px solid var(--#{$prefix}gray-400); 
    border-right:0;
}
.form-select{
    
    border: 1px solid var(--#{$prefix}gray-400);
    option{
        color:var(--#{$prefix}gray-500);
    }
    
    &:focus{
        border: 1px solid var(--#{$prefix}gray-400);
    }
}
.text-area-icon{
    position:absolute;
    font-size: 20px !important;
    top:5px;
    left:5px;
    z-index: 999;
    padding: 10px 0 0 14px;
}
    
.input-group-text{
    font-size: 14px;
    background-color: var(--#{$prefix}purple-100);
    color:var(--#{$prefix}primary);
    .pe-7s-mail,.pe-7s-call,.pe-7s-comment{
        font-weight: 700;
    }
}

.textarea{
    .input-group-text{
        border: none;
    }
}

.input-group{
    .form-control:focus
    {
        border-color: var(--#{$prefix}gray-400);
        box-shadow:none;
        outline: 0;
    }
}

.form-control{
    border :1px solid var(--#{$prefix}gray-400);
    border-radius: 3px;
    &:focus{
        border-color: var(--#{$prefix}indigo);
        outline: 0;            
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);        
    }    
}
    
.text-area{
    resize: none;
}

input{
    background-color: var(--#{$prefix}black);
    color: var(--#{$prefix}body-color);
    width:100%;
    border:none;
    &:focus{
        outline: none;
    }
}