.sidebar-content{
    border: 1px solid var(--#{$prefix}gray-400);
    .widget-heading{
        font-size: 1rem;
        color: var(--#{$prefix}dark);
    }
    .categories{
        li{
            padding: 6px 0;
            font-size: 14px;
        }
        li:hover a{
            color: var(--#{$prefix}primary);
        }
        i{
            margin-right: 10px;

        }
        .items{
            float: right;
        }
    }
    .search{
        position: absolute;
        top: 33%;
        left: 45%;
        color: var(--#{$prefix}white);
        opacity: 0;
        visibility: hidden;
        transition: $transition-base-lg;
    }
    .recent-post li{
        margin-bottom: 1rem;
        
        &:hover{
            .search{
                opacity: 1;
                visibility: visible;
                z-index: 999;
            }
            .recent-post-img::after{
                opacity: 1;
            }
        }
        
    }
    .recent-post-img{      
        &::after{
                background: rgba(182, 142, 83, 0.8);
                border-radius: 50%;
                content: "";
                height: 67px;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 2px;
                width: 67px; 
                transition: $transition-base-lg;              
        }
        i{
            transform: rotate(45deg);
        }
    }
    .recent-post-text{
        p{
            font-size: 15px;
            margin-bottom: 0;
        }
    }
    .discount-offer{
        background: url("../images/get-offer.png") no-repeat scroll center center /cover;
        padding: 20px;
        .discount-offer-inner {
            h6,span{
                color: var(--#{$prefix}white);
                font-weight: bold;
            }
            span{
                font-size: 35px;
            }
            background: rgba(0, 0, 0, 0.8);
            padding: 64px 42px;
            text-align: center;
            position: relative;

            &::after{
                border: 1px dashed var(--#{$prefix}white);
                content: "";
                height: 88%;
                left: 6%;
                position: absolute;
                top: 15px;
                width: 88%;
            }          
        } 
    }
    .tagcloud {
        a{
            border: 1px solid var(--#{$prefix}gray-400);
            padding: 8px;
            display: inline-block;
            margin-bottom: 8px;
            margin-right: 5px;
            transition: $transition-base-lg;  
            &:hover{
                color: var(--#{$prefix}white);
                background-color: var(--#{$prefix}primary);
            }
        }
    }
}   
// Side-Bar
.author-info{
    .comment-border{
        border: none;
        padding: 0;
        
    }
    
    .facebook{
        color:#3b5998;
    }
    .twitter{
        color:#00aced;
    }
    .google-plus{
        color:#c63d2d;
    }
    .dribbble{
        color:#eb5d93;
    }
    .linkedin{
        color:#007bb6;
    }
}
.reply{
    padding-left: 144px;
}
.blog-author-name,.share{
    color: var(--#{$prefix}headings-color,$headings-color);
}