@font-face {
    font-family: 'perch';
    font-display: swap;
    src:    url('../fonts/perch.eot?fx399e');
    src:    url('../fonts/perch.eot?fx399e#iefix') format('embedded-opentype'),
        url('../fonts/perch.ttf?fx399e') format('truetype'),
        url('../fonts/perch.woff?fx399e') format('woff'),
        url('../fonts/perch.svg?fx399e#perch') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="perch-"], [class*=" perch-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'perch' !important;
    speak-as: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.perch-blade:before {
    content: "\e900";
}
.perch-brush:before {
    content: "\e901";
}
.perch-comb:before {
    content: "\e902";
}
.perch-comb-double:before {
    content: "\e903";
}
.perch-comment-alt:before {
    content: "\e904";
}
.perch-head-beard-shape1:before {
    content: "\e905";
}
.perch-head-beard-shape2:before {
    content: "\e906";
}
.perch-head-beard-shape3:before {
    content: "\e907";
}
.perch-head-beard-shape4:before {
    content: "\e908";
}
.perch-head-beard-shape5:before {
    content: "\e909";
}
.perch-head-beard-shape6:before {
    content: "\e90a";
}
.perch-mirror-shape:before {
    content: "\e90b";
}
.perch-mustache-shape3:before {
    content: "\e90c";
}
.perch-mustache-shape6:before {
    content: "\e90d";
}
.perch-scissors::before {
    content: "";
    position: absolute; 
}
.perch-scissors::after {
    content: "";
}

.perch-razor1 .path1:before {
    content: "\e90e";
    color: rgb(78, 78, 78);
}
.perch-razor1 .path2:before {
    content: "\e90f";
    margin-left: -1em;
    color: rgb(78, 78, 78);
}
.perch-razor1 .path3:before {
    content: "\e910";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.perch-razor2:before {
    content: "\e911";
}

.perch-scissors .path1:before {
    content: "\e912";
    color: rgb(78, 78, 78);
}
.perch-scissors .path2:before {
    content: "\e913";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.perch-scissors .path3:before {
    content: "\e914";
    margin-left: -1em;
    color: rgb(78, 78, 78);
}
.perch-scissors .path4:before {
    content: "\e915";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
/*.perch-scissors-and-comb:before{
    content: "\e916";
}*/
.perch-scissors-and-comb .path1:before {
    content: "\e916";
    color: rgb(78, 78, 78);
}
.perch-scissors-and-comb .path2:before {
    content: "\e917";
    margin-left: -1em;
    color: rgb(78, 78, 78);
}
.perch-scissors-and-comb .path3:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(78, 78, 78);
}
.perch-scissors-and-comb .path4:before {
    content: "\e919";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.perch-Scissors-map .path1:before {
    content: "\e91a";
    color: rgb(0, 0, 0);
}
.perch-Scissors-map .path2:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.perch-Scissors-map .path3:before {
    content: "\e91c";
    margin-left: -1em;
    color: rgb(191, 148, 86);
}
.perch-Scissors-map .path4:before {
    content: "\e91d";
    margin-left: -1em;
    color: rgb(191, 148, 86);
}
.perch-Scissors-map .path5:before {
    content: "\e91e";
    margin-left: -1em;
    color: rgb(191, 148, 86);
}
.perch-Scissors-map .path6:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(191, 148, 86);
}
.perch-Scissors-map .path7:before {
    content: "\e920";
    margin-left: -1em;
    color: rgb(191, 148, 86);
}
.perch-Scissors-map .path8:before {
    content: "\e921";
    margin-left: -1em;
    color: rgb(191, 148, 86);
}
.perch-Scissors-map .path9:before {
    content: "\e922";
    margin-left: -1em;
    color: rgb(191, 148, 86);
}
.perch-user:before {
    content: "\e074";
}
.perch-image:before {
    content: "\e923";
}

.appoinment-contact-form {

    .left-icon::after {
        color: var(--#{$prefix}primary);
        content: "";
        font-family: "Pe-icon-7-stroke";
        font-size: 18px;
        left: 19px;
        position: absolute;
        top: 13px;
    }

}
